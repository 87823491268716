// Avoid `console` errors in browsers that lack a console.
(function() {
	var method;
	var noop = function () {};
	var methods = [
		"assert", "clear", "count", "debug", "dir", "dirxml", "error",
		"exception", "group", "groupCollapsed", "groupEnd", "info", "log",
		"markTimeline", "profile", "profileEnd", "table", "time", "timeEnd",
		"timeStamp", "trace", "warn"
	];
	var length = methods.length;
	var console = (window.console = window.console || {});
	while (length--) {
		method = methods[length];
		// Only stub undefined methods.
		if (!console[method]) {
			console[method] = noop;
		}
	}
}());

$(document).ready(function($) {
	// detect window-size by grabbing body::before's contents
	function deviceType() {
		return window.getComputedStyle(document.querySelector('body'), '::before').getPropertyValue('content').replace(/"/g, '').replace(/'/g, '');
	}
	//check the media query and bind corresponding events
	var mediaquery = deviceType();
	bindEvents(mediaquery);
	$(window).on("resize", function () {
		mediaquery = deviceType();
		bindEvents(mediaquery);
	});
	function bindEvents(mediaquery) {
		if (mediaquery == 'desktop') {
			console.log('mediaquery: desktop');
		} else if (mediaquery === 'tablet') {
			console.log('mediaquery: tablet');
		} else if (mediaquery === 'mobile') {
			console.log('mediaquery: mobile');
		} else {
			console.log('Huh?')
		}
	}

	// overall => disable rightmouse menu
	$(document).bind('contextmenu',function(e){
		e.preventDefault();
	});

	// work-detail => set 'work' as current menu-item
	if ($('body').hasClass('page-workdetail')) {
		$('#menu-item-30').addClass('current-menu-item');
	}

	// work-detail => set 'work' as current menu-item
	if ($('body').hasClass('page-')) {
		$('#menu-item-31').addClass('current-menu-item');
	}

	// work-detail => infobar
	if ($(window).width() > 1280) {
		$('.workdetail-infobar').click(function() {
			if ($('.workdetail-infobar').hasClass('is-maximized')) {
				$('.workdetail-infobar').removeClass('is-maximized');
				$('#moreinfo').prop('checked', false);
			} else {
				$('.workdetail-infobar').addClass('is-maximized');
				$('#moreinfo').prop('checked', true);
			}
		});
	}

	// personal => arrows on 'wideImage' instances
	$('.embed-wideImage div').on('scroll', function() {
		if($(this).scrollLeft() + $(this).innerWidth() >= ( $(this)[0].scrollWidth / 2) ) {
			$(this).parent('div').removeClass('is-initial');
		} else {
			$(this).parent('div').addClass('is-initial');
		}
	});

	// homepage => splash images
	$('.splashimages').slick({
		accessibility: true,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3500,
		centerMode: true,
		cssEase: 'ease-out',
		dots: false,
		fade: true,
		infinite: true,
		pauseOnHover: false,
		slidesToScroll: 1,
		slidesToShow: 1,
		speed: 800
	});

	// work-detail => open fullscreen gallery
	$('[data-gallery-open]').click(function() {
		initialSlide = $(this).data('photo');
		$('#inlinegallery').slick({
			accessibility: true,
			autoplay: false,
			centerMode: true,
			centerPadding: '90px',
			cssEase: 'ease-out',
			dots: false,
			fade: false,
			infinite: true,
			initialSlide: (initialSlide - 1),
			lazyLoad: 'ondemand',
			slidesToScroll: 1,
			slidesToShow: 1,
			speed: 500,
			responsive: [
				{
					breakpoint: 1200,
					settings: {
						arrows: false,
						centerPadding: '72px'
					}
				},
				{
					breakpoint: 768,
					settings: {
						arrows: false,
						centerPadding: '36px'
					}
				},
				{
					breakpoint: 480,
					settings: {
						arrows: false,
						centerPadding: '24px'
					}
				}
			]
		});
		$('.workdetail-inlinegallery').addClass('is-visible');
		$('.current').text(initialSlide);
		$('#inlinegallery').on('beforeChange ', function(event, slick, currentSlide, nextSlide) {
			$('.current').text(nextSlide + 1);
		});
		$('#inlinegallery').click();
	});

	// work-detail => close fullscreen gallery
	$('[data-gallery-close]').click(function() {
		$('.workdetail-inlinegallery').removeClass('is-visible').delay(450).queue(function() { // short delay to prevent flickering
			$('#inlinegallery').slick('unslick').dequeue();
		});
	});

	// init Isotope
	var $grid = $('.masonry').isotope({
		itemSelector: 'li',
		percentPosition: true,
		masonry: {
			columnWidth: 'li'
		}
	});
	// layout Isotope after each image loads
	$grid.imagesLoaded().progress( function() {
		$grid.isotope('layout');

		// overall => masonry fadeIns
		randomdelayRepeater = 1;
		$(document).on('resize scroll load', function() {
			var scroll_position = $(window).scrollTop(),
				viewport_height = $(window).height();

			$('.masonry li').each(function () {
				randomdelay = Math.floor((Math.random() * 800) + 350);
				var element_position = $(this).offset().top + 10;
				if ((scroll_position + viewport_height) > element_position) {
					$(this).addClass('is-waiting').delay(randomdelay).queue(function() {
						$(this).addClass('is-visible');
						console.log(randomdelay);
						if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
							console.log('Repaint for safari fired');
							$grid.isotope('layout');
						}
						randomdelayRepeater++;
					});
				}
			});
		});

		if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
			console.log('Repaint for safari fired');
			$grid.isotope('layout');
		}

		if($(document).scrollTop() === 1) {
			window.scroll(0, 0);
			if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
				console.log('Repaint for safari fired');
				$grid.isotope('layout');
			}
		} else {
			window.scroll(0, 1);
			if ( /^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
				console.log('Repaint for safari fired');
				$grid.isotope('layout');
			}
		}
	});

	// filter functions
	// bind filter button click
	$('#filters').on( 'click', 'a', function() {
		var filterValue = $( this ).attr('data-filter');
		// use filterFn if matches value
		$grid.isotope({ filter: filterValue });

		$('.masonry li').addClass('is-waiting is-visible');

	});
	// change is-checked class on buttons
	$('.masonry-filter').each( function( i, buttonGroup ) {
		var $buttonGroup = $(buttonGroup);
		$buttonGroup.on('click', 'a', function() {
			$buttonGroup.find('.is-current').removeClass('is-current');
			$( this ).addClass('is-current');
		});
	});




// 	// layout Isotope after each image loads
// 	$grid.imagesLoaded().progress( function() {
// 		$grid.isotope('layout');
//
// 		// overall => masonry fadeIns
// 		randomizercounter = 1;
// 		$(document).on('resize scroll load', function() {
// 			var scroll_position = $(window).scrollTop(),
// 				viewport_height = $(window).height();
//
// 			$('.masonry li').not('.is-waiting').each(function(randomizercounter) {
// 				randomdelay = Math.floor((Math.random() * 800) + 350);
// 				var element_position = $(this).offset().top + 10;
// 				if ((scroll_position + viewport_height) > element_position) {
// 					$(this).addClass('is-waiting').delay(randomdelay).queue(function() {
// 						$(this).addClass('is-visible');
// 						console.log(randomdelay);
// 						randomizercounter++;
// 					});
// 				}
// 			});
// 		});
// 	});
// // filter functions
// // bind filter button click
// 	$('#filters').on( 'click', 'a', function() {
// 		var filterValue = $( this ).attr('data-filter');
// 		// use filterFn if matches value
// 		$grid.isotope({ filter: filterValue });
// 	});
// // change is-checked class on buttons
// 	$('.masonry-filter').each( function( i, buttonGroup ) {
// 		var $buttonGroup = $(buttonGroup);
// 		$buttonGroup.on('click', 'a', function() {
// 			$buttonGroup.find('.is-current').removeClass('is-current');
// 			$( this ).addClass('is-current');
// 		});
// 	});






	currentPage = $('.current-menu-item').attr('id');
	$('#openContact').on('click', function() {
		console.log(currentPage);
		if ($('.block-contact').hasClass('is-visible')) {
			// close contact-pane
			$('.block-contact').removeClass('is-visible');
			$('#openContact').removeClass('current-menu-item');
			$('#' + currentPage).addClass('current-menu-item');
			$('body').removeClass('is-fixed');
		} else {
			// open contact-pane
			$('#trigger-headermenu').prop('checked', false);
			$('.block-contact').addClass('is-visible').off('wheel mousewheel');
			$('#openContact').addClass('current-menu-item');
			$('#' + currentPage).removeClass('current-menu-item');
			$('body').addClass('is-fixed');
		}
	});
	$('.block-contact').on('click', function() {
		// close contact-pane
		$('.block-contact').removeClass('is-visible');
		$('#openContact').removeClass('current-menu-item');
		$('#' + currentPage).addClass('current-menu-item');
		$('body').off('wheel mousewheel');
	});
	$('#closeContact').click(function() {
		$('#openContact').click();
	});

	$('#openAbout').on('click', function() {
		console.log(currentPage);
		if ($('.block-about').hasClass('is-visible')) {
			// close contact-pane
			$('.block-about').removeClass('is-visible');
			$('#openAbout').removeClass('current-menu-item');
			$('#' + currentPage).addClass('current-menu-item');
			$('body').off('wheel mousewheel');
		} else {
			// open contact-pane
			// $('#trigger-headermenu').prop('checked', false);
			$('.block-about').addClass('is-visible').off('wheel mousewheel');
			$('#openAbout').addClass('current-menu-item');
			$('#' + currentPage).removeClass('current-menu-item');
			$('body').on('wheel mousewheel', function () {
				return false;
			});
		}
	});
	$('.block-about').on('click', function() {
		// close contact-pane
		$('.block-about').removeClass('is-visible');
		$('#openAbout').removeClass('current-menu-item');
		$('#' + currentPage).addClass('current-menu-item');
		$('body').off('wheel mousewheel');
	});
	$('#closeAbout').click(function() {
		$('#openAbout').click();
	});
	$(document).keyup(function(e) {
		if (e.keyCode == 27) { // escape key maps to keycode `27`
			if ($('.block-contact').hasClass('is-visible')) {
				// close contact-pane
				$('.block-contact').removeClass('is-visible');
				$('#openContact').removeClass('current-menu-item');
				$('#' + currentPage).addClass('current-menu-item');
			}
			if ($('.block-about').hasClass('is-visible')) {
				// close contact-pane
				$('.block-about').removeClass('is-visible');
				$('#openAbout').removeClass('current-menu-item');
				$('#' + currentPage).addClass('current-menu-item');
			}
		}
	});

	// overall => resize .header-main on scroll
	$(document).scroll(function() {
		if ($(document).scrollTop() >= 10) {
			$('.header-main').addClass('is-slim');
			if ($('body').hasClass('page-home')) {
			}
		} else {
			$('.header-main').removeClass('is-slim');
		}
	});

});